import React from 'react';
import { Box, Heading, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Text } from '@chakra-ui/react';
import {useTranslation} from "next-i18next";



const FaqSection = () => {
  const faqQuestions = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];

  const { t } = useTranslation('landing');
  return (
    <Box as="section" bg="white" py={10} px={4} id="faq">
      <Box maxW="1200px" mx="auto" textAlign="center" mb={10}>
        <Heading as="h2" size="xl" color="blue.700">
          {t('landing:kkk.title')}
        </Heading>
      </Box>
      <Box maxW="1200px" mx="auto">
        <Accordion allowToggle>
          {faqQuestions.map((num) => {
            return (
              <AccordionItem key={`item-${num}`} py={4}>
                <AccordionButton _expanded={{ bg: 'orange.100' }}>
                  <Text flex="1" textAlign="left" fontWeight="bold" fontSize="xl">
                    {t(`landing:kkk.${num}.title`)}
                  </Text>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel py={4}>
                  {t(`landing:kkk.${num}.desc`)}
                </AccordionPanel>
              </AccordionItem>
            )
          })}
        </Accordion>
      </Box>
    </Box>
  );
};

export default FaqSection;
