import React from 'react'
import NextLink from 'next/link'
import { Link } from '@chakra-ui/react'

export const LeicaLink = (props: any) => {
  const { href, children, ...rest } = props

  return (
    <NextLink href={href as string} passHref>
      <Link>
        {children}
      </Link>
    </NextLink>
  )
}
