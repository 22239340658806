import { Box, Container, Heading, Stack, Text, Image } from "@chakra-ui/react"
import {useTranslation} from "next-i18next";

const illustration1 = "/images/new/1 illustration.svg";
const illustration2 = "/images/new/2 illustration.svg";
const illustration3 = "/images/new/3 illustration.svg";
const illustration4 = "/images/new/4 illustration.svg";


const HowItWorksSection = () => {
  const {t} = useTranslation('landing');
  return (
    <Box as="section" bg="white" py={10} id="how-it-works">
      <Heading as="h2" size="xl" textAlign="center" mb={10} color="blue.700">
        {t('landing:block2')}
      </Heading>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="center"
        spacing={8}
        maxW="1200px"
        mx="auto"
        px={4}
      >
        <Box textAlign="center" maxW="250px">
          <Image src={illustration1} alt="Step 1" mb={4} />
          <Text>{t('landing:block3.1')}</Text>
        </Box>
        <Box textAlign="center" maxW="250px">
          <Image src={illustration2} alt="Step 2" mb={4} />
          <Text>{t('landing:block3.2')}</Text>
        </Box>
        <Box textAlign="center" maxW="250px">
          <Image src={illustration3} alt="Step 3" mb={4} />
          <Text>{t('landing:block3.3')}</Text>
        </Box>
        <Box textAlign="center" maxW="250px">
          <Image src={illustration4} alt="Step 4" mb={4} />
          <Text>{t('landing:block3.4')}</Text>
        </Box>
      </Stack>
    </Box>
  );
}

export default HowItWorksSection;
