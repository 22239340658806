import React from 'react'
import { Box } from '@chakra-ui/layout'
import {
  Center,
  Flex,
  Heading,
  Icon,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react'
import Logo from './Logo'
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa'
import { LeicaLink } from './ALink'
import { TFunction, useTranslation } from 'next-i18next'

interface FooterSection {
  name: string
  links: Array<{ name: string; link: string; external?: boolean }>
}

const data = (t: TFunction): FooterSection[] => {
  return [
    {
      name: t('footer:usefulInfoSection'),
      links: [
        {
          name: t('footer:termsAndConditions'),
          link: '/kasutustingimused',
        },
        {
          name: t('footer:contact'),
          link: '/kontakt',
        },
      ],
    },
    {
      name: t('footer:servicesSection'),
      links: [
        {
          name: t('footer:addService'),
          link: 'https://docs.google.com/forms/d/e/1FAIpQLSfRhpGglUMT5uMawpnlwbefjqLtqvx0Z-eUb-8XVRH4s6sJ6Q/viewform',
          external: true,
        },
        {
          name: t('footer:services'),
          link: '/teenusepakkujad',
        },
      ],
    },
  ]
}

export default function Footer() {
  const { t } = useTranslation()

  return (
    <Box as="footer" m="none" textColor="white">
      <Box bg="blue1" pt={10} pb={10}>
        <Box width={4 / 7} mx="auto">
          <Stack
            justify="space-between"
            direction={{ base: 'column', md: 'row' }}
            spacing={10}
          >
            <Box textColor="white">
              <Stack>
                <Logo />
                <Text>{t('footer:infoEmail')}</Text>
                <Flex>
                  <Link href="https://facebook.com/leiacatering" isExternal>
                    <Icon as={FaFacebookSquare} h={10} w={10} />
                  </Link>
                  <Link href="https://instagram.com/leiacatering" isExternal>
                    <Icon as={FaInstagramSquare} h={10} w={10} />
                  </Link>
                </Flex>
              </Stack>
            </Box>
            {data(t).map((section) => {
              return (
                <Box key={section.name}>
                  <Stack>
                    <Heading size="sm">{section.name.toUpperCase()}</Heading>
                    {section.links.map((link) => {
                      return (
                        <LeicaLink
                          href={link.link}
                          key={link.name}
                          isExternal={link.external}
                        >
                          {link.name}
                        </LeicaLink>
                      )
                    })}
                  </Stack>
                </Box>
              )
            })}
          </Stack>
        </Box>
        <Center mt={10}>
          <Heading size="sm">{t('footer:copyright')}</Heading>
        </Center>
      </Box>
    </Box>
  )
}
