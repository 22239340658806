import { Box, Flex, Heading, Text, Image, Stack } from '@chakra-ui/react';
import {useTranslation} from "next-i18next";
const teamImage = '/images/team-image.png'; // Replace with actual path
const cateringImage = '/images/team-image.png'; // Replace with actual path

const AboutUsSection = () => {
  const { t } = useTranslation('landing');
  return (
    <Box as="section" bg="gray.50" py={10} px={4} id="about-us">
      <Box maxW="1200px" mx="auto" mb={10}>
        <Heading as="h2" size="xl" color="blue.700" textAlign="center" mb={6}>
          {t('menu:aboutUs')}
        </Heading>
        <Flex direction={{ base: 'column', md: 'row' }} align="center" justify="space-between">
          <Image
            src={teamImage}
            alt="Team"
            borderRadius="md"
            boxSize={{ base: '100%', md: '50%' }}
            mb={{ base: 4, md: 0 }}
            mr={{ base: 0, md: 4 }}
          />
          <Box flex="1">
            <Text fontSize="lg" mb={4}>
              {t('landing:aboutus.1')}
            </Text>
            <Text fontSize="lg" mb={4}>
              {t('landing:aboutus.2')}
            </Text>
          </Box>
        </Flex>
        <Flex direction={{ base: 'column', md: 'row-reverse' }} align="center" justify="space-between" mt={6}>
          <Box flex="1">
            <Text fontSize="lg" mb={4}>
              {t('landing:aboutus.3')}
            </Text>
            <Text fontSize="lg" mb={4}>
              {t('landing:aboutus.4')}
            </Text>
            <Text fontSize="lg" mb={4}>
              {t('landing:aboutus.5')}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};

export default AboutUsSection;
