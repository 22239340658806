import React, { useState} from 'react';
import { Box, Heading, InputGroup, InputLeftElement, Stack, Select, Image, Button, Text } from "@chakra-ui/react";
import { FiCalendar } from "react-icons/fi";
import { LeicaLink } from "../ALink";
import DatePicker from "../date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next';
import { formatDate } from "date-fns";
import { County, EventType } from '../../types';

interface HeroProps {
  counties: County[];
  eventTypes: EventType[]
}

const HeroSection = (props: HeroProps) => {
  const { counties , eventTypes} = props;

  const {t} = useTranslation(['home', 'landing']);
  const router = useRouter();
  const locale = router.locale as string

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedCounty, setSelectedCounty] = useState(null);
  const [selectedEventType, setSelectedEventType] = useState(null);

  const dateString = selectedDate ? formatDate(selectedDate, "yyyy-MM-dd") : "";

  //const dateString = `${year}-${month}-${day}`;
  const askOfferParams = `?selectedDate=${dateString}&selectedCounty=${selectedCounty}&selectedEventType=${selectedEventType}`;

  return (
    <Box as="section" bg="blue1" py={{base: 1, md: 5, lg: 10}}>
      <Stack
        direction={{ base: 'column', lg: 'row', xl: 'row' }}
        align="center"
        justify="space-between"
        wrap="nowrap"
        maxW={{ base: 'auto', md: '80%', lg: '900px', xl: '1200px' }}
        m="auto"
        spacing={10}
        h="400px"
        w="auto"
      >
        <Stack
          spacing={6}
          mr={4}
          mt={{ base: 6, lg: 12 }}
          mx={{ base: 6, lg: 0 }}
          maxW="lg"
        >
          <Box>
            <Heading as="h2" color="white" size="2xl">
              <Text fontWeight="500">{t('landing:block1')}</Text>
            </Heading>
          </Box>

        </Stack>
        <Box w="50%" position="relative" display="flex" h="100%" justifyContent="center" alignItems="center">
          <Box w={0.6} h="auto">
            <Image
              borderRadius="10px"
              src='/images/new/photo 3.png'
              alt='First Image'
            />
          </Box>
          <Box w={0.35} h="auto" position="absolute" bottom="0"
            right="0"
          >
            <Image
              borderRadius="10px"
              src='/images/new/photo 2.png'
              alt='Second Image'
              h="100%"
              w="100%"
            />
          </Box>
          <Box w={0.35}
            h="auto"
            position="absolute"
            bottom="0"
            left="0"

          >
            <Image
              borderRadius="10px"
              src='/images/new/photo 1.png'
              alt='Second Image'
              h="100%"
              w="100%"
            />
          </Box>
        </Box>
      </Stack>
      <Stack
        align="center"
        justify="center"
        spacing={4}
        mt={10}
      >
        <Stack direction={{ base: 'column', md: 'row' }} align="center" justify="center" spacing={4} mt={10}>
          <Select placeholder={t('services:counties')} bg="white" maxW="300px" size="lg" onChange={(x) => setSelectedCounty(x.target.value)}>
            {counties.map((county) => (
              <option value={county.id} key={county.slug}>
                {county.long_name}
              </option>
            ))}
          </Select>
          <Select placeholder={t('Forms:eventType')} bg="white" maxW="300px" size="lg" onChange={(x) => setSelectedEventType(x.target.value)}>
            {eventTypes.map((eventType) => {
              return (
                <option value={eventType.id} key={eventType.slug}>
                  {t(`choices:EventType.${eventType.slug}`)}
                </option>
              );
            })}
          </Select>
          <InputGroup maxW="300px">
            <InputLeftElement pointerEvents="none">
              <FiCalendar color="gray.300" />
            </InputLeftElement>
            <DatePicker
              locale={locale}
              selectedDate={selectedDate}
              onChange={(date) => setSelectedDate(date)}/>
          </InputGroup>
        </Stack>
        <LeicaLink href={`/kysi-pakkumist/catering${askOfferParams}`}>
          <Button colorScheme="orange" size="xl">
            {t('home:heroButtonAskOffer')}
          </Button>
        </LeicaLink>
      </Stack>
    </Box>
  );
};

export default HeroSection;
