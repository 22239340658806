import React, { FC } from 'react'
import { Box } from '@chakra-ui/layout'

interface Props {
  nothing?: boolean // not sure how to pass empty props
}

const Container: FC<Props> = ({ children }) => {
  return (
    <Box
      maxW={{ base: 'auto', md: '80%', lg: '900px', xl: '1200px' }}
      m="auto"
      px={{ base: 5, md: 0 }}
    >
      {children}
    </Box>
  )
}

export default Container
