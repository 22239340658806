import * as React from 'react'
import {
  Text,
  Button,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  Image,
  Center,
} from '@chakra-ui/react'

import { useRouter } from 'next/router'

const LocaleButton: React.FC = () => {
  const router = useRouter()

  const locale = router.locale as string

  function change(_locale: string) {
    void router.replace(router.route, router.asPath, { locale: _locale })
  }

  return (
    <Menu>
      <Tooltip hasArrow label="Vali keel ✨">
        <MenuButton as={Button} variant="ghost">
          <Image
            rounded="md"
            border="1px"
            borderColor="gray.300"
            shadow="lg"
            width="30px"
            _hover={{ height: '21px' }}
            src={`/flags/${locale}.svg`}
            alt="lipp"
          />
        </MenuButton>
      </Tooltip>
      <MenuList>
        <MenuGroup title="Vali keel">
          <MenuItem onClick={() => change('et')}>
            <Image
              rounded="md"
              border="1px"
              borderColor="gray.300"
              shadow="lg"
              width="30px"
              _hover={{ height: '21px' }}
              src={`/flags/et.svg`}
              alt="Eesti lipp"
            />
            <Text ml={5}>eesti</Text>
          </MenuItem>
          <MenuItem onClick={() => change('ru')}>
            <Image
              rounded="md"
              border="1px"
              borderColor="gray.300"
              shadow="lg"
              width="30px"
              _hover={{ height: '21px' }}
              src={`/flags/ru.svg`}
              alt="Russian lipp"
            />
            <Text ml={5}>русский</Text>
          </MenuItem>
          <MenuItem onClick={() => change('en')}>
            <Image
              rounded="md"
              border="1px"
              borderColor="gray.300"
              shadow="lg"
              width="30px"
              _hover={{ height: '21px' }}
              src={`/flags/en.svg`}
              alt="Great Britain flag"
            />
            <Text ml={5}>english</Text>
          </MenuItem>
          <MenuItem onClick={() => change('fi')}>
            <Image
              rounded="md"
              border="1px"
              borderColor="gray.300"
              shadow="lg"
              width="30px"
              _hover={{ height: '21px' }}
              src={`/flags/fi.svg`}
              alt="Finnish flag"
            />
            <Text ml={5}>suomi</Text>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}

export default LocaleButton
