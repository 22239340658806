import { Box, Stack, Heading, Image, Button, Center } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import Slider from "react-slick";
import { LeicaLink } from '../ALink'

const formLink =
    'https://docs.google.com/forms/d/e/1FAIpQLSfRhpGglUMT5uMawpnlwbefjqLtqvx0Z-eUb-8XVRH4s6sJ6Q/viewform'

const partners = [
  "7Käiku.jpg",
  "Caviar.png",
  "Charmfood.jpg",
  "Deluxe.jpg",
  "Fee.jpg",
  "Foodtrip.jpg",
  "Fredo.jpg",
  "Gustav.jpg",
  "Holeinone.jpg",
  "Katariina-logo-uus.png",
  "Kopli.png",
  "Küti.jpg",
  "Maru.jpg",
  "Mona.jpg",
  "Muna.jpg",
  "Poni.jpg",
  "Q.jpg",
  "rebase.jpg",
  "Reval.png",
  "Roseni.jpg",
  "Royal.jpg",
  "Sirli.jpg",
  "SushiTiger.jpg",
  "Tark.png",
  "Uskumatu.png",
  "Viktus.jpg",
]

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 4,
  rows: 2,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 1
      }
    }
  ]
};

const OurPartnersSection = () => {
  const {t} = useTranslation();

  return (
    <Box as="section" bg="white" py={10} px={4} id="our-partners">
      <Box
        maxW="1200px"
        mx="auto"
        p={6}
        border="2px"
        borderColor="blue.400"
        borderRadius="md"
        bg="gray.50"
        textAlign="center"
      >
        <Heading as="h2" size="lg" mb={6} color="blue.700">
          {t('landing:partners')}
        </Heading>
        <Box
          maxW="1200px" mx="auto"
        >
          <Slider {...settings}>
            {partners.map((partner, index) => (
              <Box
                key={index}
                p={6}
                textAlign="center"
                mx={"auto"}
              >
                <Image src={`images/partners/${partner}`} alt="Partner 1" boxSize="100px" objectFit="contain" />
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
      <Center mt={10}>
        <LeicaLink href={formLink} isExternal py={{ base: 2, md: 0, lg: 0 }}>
          <Button
            colorScheme="blue"
            size="lg"
            alignSelf="center"
          >
            {t('menu:forServices')}
          </Button>
        </LeicaLink>
      </Center>
    </Box>
  );
};

export default OurPartnersSection;
