import React from 'react'
import { LeicaLink } from './ALink'
import { Image } from '@chakra-ui/image'

const Logo = () => {
  return (
    <LeicaLink href="/">
      <Image
        h={16}
        src={'/images/leia_logo.svg'}
        alt="Leiacatering.ee logo"
      />
    </LeicaLink>
  )
}

export default Logo
