import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {useTranslation} from "next-i18next";

const testimonials = [
  {
    text: "Hea võimalus efektiivsust hindavale inimesele",
    author: "Piret Talur",
    date: "20.08.22"
  },
  {
    text: "Suur tänu. Olime avastatud teenindusega väga rahul",
    author: "Liis Burk",
    date: "26.09.22"
  },
  {
    text: "Aitäh, et aitasite elu lihtsamaks teha",
    author: "Sessi Popel",
    date: "29.11.22"
  },
  {
    text: "Mugav keskkond, mida kasutan ka edaspidi",
    author: "Heleriin Teder, Häirekeskus",
    date: "02.11.22"
  },
  {
    text: "Suur aitäh pakkumise eest! Kõik pakkumused olid väga head ja väga raske oli valikut teha, sest ka hinnaklass oli enamvähem sama.",
    author: "Maris Arro",
    date: "23.10.23"
  },
  {
    text: "Tänud kiire vastuse ja toreda pakkumise eest. Olen seekord leidnud sobiva lahenduse heade kolleegide abil, aga platvorm on tõesti väga efektiivne.",
    author: "Halliki Kõdar",
    date: "08.02.24"
  },
  {
    text: "Üritus läks hästi ja leidsime kenasti korraldaja, suured tänud",
    author: "Ragnar Landsov, ABC Kliima",
    date: "13.02.24"
  },
  {
    text: " Suur tänu! Teie teenusest oli väga palju kasu! Pidustusega jäime väga rahule.",
    author: "Signe Freienthal",
    date: "01.03.24"
  },
];

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      }
    ]
  };
  const {t} = useTranslation('landing');
  return (
    <Box as="section" bg="white" py={10} px={4} id="customers-about-us">
      <Box maxW="1200px" mx="auto" textAlign="center" mb={10}>
        <Heading as="h2" size="xl" color="blue.700">
          {t('landing:clients.title')}
        </Heading>
      </Box>
      <Box maxW="1200px" mx="auto">
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <Box
              key={index}
              p={5}
            >
              <Box
                bg="gray.50"
                borderRadius="md"
                boxShadow="md"
                textAlign="center"
                h="250px"
                p={10}
                display="flex"
                flexDirection="column"
            >
                <Text fontSize="lg" mb={4}>&quot;{testimonial.text}&quot;</Text>
                <Box mt="auto">
                  <Text fontSize="md" fontWeight="bold">{testimonial.author}</Text>
                  <Text fontSize="sm" color="gray.500">{testimonial.date}</Text>
                </Box>

            </Box>
            </Box>

          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Testimonials;
