import { Box, Stack, Heading, Text } from '@chakra-ui/react';
import {useTranslation} from "next-i18next";

const WhyUsSection = () => {

  const {t} = useTranslation('hero');
  return (
    <Box as="section" bg="white" py={10} px={4} id="why-us">
      <Box maxW="1200px" mx="auto" textAlign="center" mb={10}>
        <Heading as="h2" size="xl" color="blue.700">
          {t("info.whyUs")}
        </Heading>
      </Box>
      <Stack
        direction={{ base: 'column', md: 'row' }}
        spacing={10}
        justify="space-between"
        maxW="1200px"
        mx="auto"
        px={4}
        textAlign="left"
      >
        <Stack direction="column" align="center" spacing={4} flex="1">
          <Text fontSize="6xl" color="orange.400" fontWeight="bold">
            1
          </Text>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              {t('info.time.title')}
            </Heading>
            <Text>
              {t('info.time.description')}
            </Text>
          </Box>
        </Stack>
        <Stack direction="column" align="center" spacing={4} flex="1">
          <Text fontSize="6xl" color="orange.400" fontWeight="bold">
            2
          </Text>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              {t('info.comfort.title')}
            </Heading>
            <Text>
              {t('info.comfort.description')}
            </Text>
          </Box>
        </Stack>
        <Stack direction="column" align="center" spacing={4} flex="1">
          <Text fontSize="6xl" color="orange.400" fontWeight="bold">
            3
          </Text>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              {t('info.trust.title')}
            </Heading>
            <Text>
              {t('info.trust.description')}
            </Text>
          </Box>
        </Stack>
        <Stack direction="column" align="center" spacing={4} flex="1">
          <Text fontSize="6xl" color="orange.400" fontWeight="bold">
            4
          </Text>
          <Box>
            <Heading as="h3" size="md" mb={2}>
              {t('info.price.title')}
            </Heading>
            <Text>
              {t('info.price.description')}
            </Text>
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default WhyUsSection;
