import React, { HTMLAttributes, useState, forwardRef } from 'react';
import { Input } from "@chakra-ui/react";
import ReactDatePicker from 'react-datepicker';
import { et } from 'date-fns/locale/et';
import { fi } from 'date-fns/locale/fi';
import { ru } from 'date-fns/locale/ru';
import { enUS } from 'date-fns/locale/en-US';
import 'react-datepicker/dist/react-datepicker.css';
import {useTranslation} from "next-i18next";
import { Global, css } from '@emotion/react';

interface Props {
  isClearable?: boolean;
  locale: string;
  onChange: (date: Date) => any;
  selectedDate: Date | undefined;
  selectedTime: string | undefined;
  showPopperArrow?: boolean;
}

const CustomInput = forwardRef(({ value, onClick }, ref) => {
  const {t} = useTranslation("landing");
  return (
    <Input pl={10} ref={ref} value={value} onClick={onClick} readOnly size="lg" bg="white" type="text" placeholder={t("landing:date")} />
  )
});

CustomInput.displayName = "CustomInput";

const DatePicker = ({
  onChange,
  selectedDate,
  locale,
  isClearable = false,
  showPopperArrow = false,
  placeholder,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  let dateLocale = et;
  switch (locale) {
    case 'et':
      dateLocale = et;
      break;
    case 'fi':
      dateLocale = fi;
      break;
    case 'ru':
      dateLocale = ru;
      break;
    default:
      dateLocale = enUS;
  }

  return (
    <ReactDatePicker
      selected={selectedDate}
      onChange={onChange}
      isClearable={isClearable}
      customInput={<CustomInput />}
      dateFormat="dd-MM-YYYY"
      showPopperArrow={showPopperArrow}
      locale={dateLocale}
      {...props}
    />
  );
};

export const FormDatePicker = ({
  onChange,
  onBlur,
  selectedDate,
  locale,
  placeholder,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {
  const DatePickerStyles = () => (
    <Global
      styles={css`
        .react-datepicker-wrapper {
          display: block;
          width: 100%;
        }
      `}
    />
  );

  let dateLocale = et;
  switch (locale) {
    case 'et':
      dateLocale = et;
      break;
    case 'fi':
      dateLocale = fi;
      break;
    case 'ru':
      dateLocale = ru;
      break;
    default:
      dateLocale = enUS;
  }

  return (
    <>
      <DatePickerStyles />
      <ReactDatePicker
        selected={selectedDate}
        onChange={onChange}
        onBlur={onBlur}
        customInput={<Input w="100%" type="text" />}
        dateFormat="dd-MM-YYYY"
        showPopperArrow={true}
        style={{ width: '100%' }}
        locale={dateLocale}
        {...props}
      />
    </>

  );
};

export const FormTimePicker = ({
  onChange,
  onBlur,
  selectedTime,
  locale,
  placeholder,
  ...props
}: Props & HTMLAttributes<HTMLElement>) => {

  let dateLocale = et;
  switch (locale) {
    case 'et':
      dateLocale = et;
      break;
    case 'fi':
      dateLocale = fi;
      break;
    case 'ru':
      dateLocale = ru;
      break;
    default:
      dateLocale = enUS;
  }

  return (
    <>
      <ReactDatePicker
        selected={selectedTime}
        onChange={onChange}
        onBlur={onBlur}
        customInput={<Input w="100%" />}
        showTimeSelect={true}
        showTimeSelectOnly={true}
        timeCaption=""
        dateFormat={"p"}
        locale={dateLocale}
        {...props}
      />
    </>

  );
};



export default DatePicker;
