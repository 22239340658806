import type { GetServerSideProps, NextPage } from 'next'
import React from 'react'
import {County, EventType, SettingsObject, TopService} from '../types'
import { getTopServices } from '../api/serviceHandler'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { getMetaSettings } from '../api/settingsHandler'
import Navbar from '../components/Navbar'
import HeroSection from '../components/main/hero'
import Footer from '../components/Footer'
import HowItWorksSection from '../components/main/HowItWorksSection';
import { Stack, Image, Center, Button } from '@chakra-ui/react'
import CompanyInNumbersSection from '../components/main/CompanyInNumbersSection'
import WhyUsSection from '../components/main/WhyUsSection'
import OurPartnersSection from '../components/main/OurPartnersSection'
import CustomersAboutUsSection from '../components/main/CustomersAboutUsSection'
import AboutUsSection from '../components/main/AboutUs'
import FaqSection from '../components/main/FaqSection'
import {getCounties, getEventTypes} from "../api/countiesHandler";
import BottomCallToActionSection from '../components/main/BottomCallToActionSection'
import { LeicaLink } from '../components/ALink'
import { useTranslation } from 'react-i18next'
import Head from 'next/head'


interface HomeProps {
  topServices: TopService[]
  counties: County[]
  eventTypes: EventType[]
  metaSettings: SettingsObject[]
}

const Home: NextPage<HomeProps> = (props) => {
  const { metaSettings, counties , eventTypes} = props
  const { t } = useTranslation();

  // TODO: i18n
  const description =
    metaSettings['meta_description_index'] ||
      'Leia enda üritusele mõne klikiga sobiv teenusepakkuja. Täida väljad ja sinu päring jõuab üle 300 toitlustusettevõttele. Teenus on täiesti TASUTA!'

  return (
    <>
      <Head>
        <title>Leiacatering - Eesti suurim catering pakkujate platvorm</title>
        <meta name="msvalidate.01" content="0623A68C58C142090C3F3DE2C3FD1E40" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content={description} />
        <meta
          name="keywords"
          content="catering tallinnas, catering harjumaal, catering tartus, catering lõuna-eestis, catering rakveres, catering viljandis, catering lääne-virumaal, catering pärnu, catering haapsalu, soodne,catering, parim catering tallinnas, suupisted catering, pulma catering, sünnipäeva catering"
        />
      </Head>
      <Stack spacing={0} h="100%">
        <Navbar />
        <HeroSection counties={counties} eventTypes={eventTypes} />
        <HowItWorksSection />
        <CompanyInNumbersSection />
        <WhyUsSection />
        <Center>
          <LeicaLink href={`/kysi-pakkumist/catering`}>
            <Button colorScheme="orange" size="xl">
              {t('home:heroButtonAskOffer')}
            </Button>
          </LeicaLink>
        </Center>
        <OurPartnersSection />
        <Center>
          <Image src="/images/new/Photo.png" alt="Partner 1" height="400px" objectFit="cover"/>
        </Center>
        <CustomersAboutUsSection />
        <AboutUsSection />
        <FaqSection />
        <BottomCallToActionSection />
        <Footer />
      </Stack>
    </>
  )
}

export default Home

export const getServerSideProps: GetServerSideProps = async ({ locale }) => {
  const topServices = await getTopServices()
  const translations = await serverSideTranslations(locale as string, [
    'landing',
    "Forms",
    "services",
    "choices",
    'common',
    'footer',
    'menu',
    'home',
    'hero',
  ])
  const apiKey = '692bdf4f5e4a9fa02c8d6a1683db211ae113994e'
  const metaSettings = await getMetaSettings(apiKey)

  const counties = await getCounties();
  const eventTypes = await getEventTypes();

  return {
    props: {
      topServices,
      counties,
      eventTypes,
      metaSettings,
      ...translations,
    },
  }
}
